var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.NuevoActivo)?_c('Nuevo',{attrs:{"NuevoActivo":_vm.NuevoActivo,"Accion":_vm.Accion,"Item":_vm.Item},on:{"NuevoActivo":function($event){_vm.NuevoActivo = $event}}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-12 mt-5",attrs:{"headers":_vm.headers,"items":_vm.arreglo,"items-per-page":5,"search":_vm.busqueda,"dense":"","sort-by":"id","sort-desc":"true","loading":_vm.TablaVacia,"loading-text":"Cargando... Espere por favor"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.titulo))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"BUSQUEDA","single-line":"","hide-details":""},model:{value:(_vm.busqueda),callback:function ($$v) {_vm.busqueda=$$v},expression:"busqueda"}}),_c('v-spacer'),(_vm.DatosdeUsuario.perfil != 3 && _vm.DatosdeUsuario.perfil !=4)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-checkbox',_vm._g(_vm._b({attrs:{"color":"primary","value":"red","hide-details":""},on:{"click":_vm.MostrarItems},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Suspender "),_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]},proxy:true}],null,true),model:{value:(_vm.checkitems),callback:function ($$v) {_vm.checkitems=$$v},expression:"checkitems"}},'v-checkbox',attrs,false),on))]}}],null,false,677380470)},[_c('span',[_vm._v("Click para mostrar cuentas a suspender")])]):_vm._e()],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.mostrarItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("Mostrar Item")])]),(item.chequeo == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.Suspender(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v("Suspender")])]):_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"380px"},model:{value:(_vm.DialogSuspender),callback:function ($$v) {_vm.DialogSuspender=$$v},expression:"DialogSuspender"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Suspender ")]),_c('v-card-text',{staticClass:"mt-0 mb-n5"},[_c('v-card-title',[_vm._v("¿Desea suspender el ID # "+_vm._s(_vm.ItemSuspender.id)+"?")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.DialogSuspender = false}}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.SuspenderConfirm}},[_vm._v("Aceptar")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }