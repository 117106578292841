<template>
  <div class="ventaseps">
    <tabla titulo="Ventas Seguridad Social" 
          :headers = "headers"
          :arreglo = "VentasEpsGetter.items"
            />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import tabla from '@/components/ventaseps/';

export default {
  name: 'ventaseps',

   components: {
    tabla
  },
  
  created(){
    this.ConsultarVentasEps()
  },

  /*
  {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'PUNTO DE VENTA', value: 'razon_social' },
  */
  data: () =>{
    return{
    headers: [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'PUNTO DE VENTA', value: 'razon_social', sortable: true },
        { text: 'CLIENTE', value: 'nombre_cliente', sortable: true },
        { text: 'CELULAR', value: 'cliente', sortable: true },
        { text: 'PRECIO VENTA', value: 'precio_venta', sortable: true },
        { text: 'FECHA COMPRA', value: 'fecha_compra_format', sortable: true },
        { text: 'FECHA VENCE', value: 'fecha_vence_format', sortable: true },
        { text: 'CHEQUEADO', value: 'chequeado', sortable: true },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  methods: {
    ...mapActions(['ConsultarVentasEps']),

  },

  computed:{
      ...mapGetters(['VentasEpsGetter'])
  }
}
</script>
